import { Component, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SEOService } from '@shared/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {
  private meta: Meta = inject(Meta);
  private seoService: SEOService = inject(SEOService);

  public constructor() {
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    this.seoService.updateTitle('404 Not Found');
    this.seoService.updateDescription('404 Not Found');
    this.seoService.updateURL(`${environment.siteURL}/not-found`);
  }
}

import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { ENABLE_PERSISTENCE } from '@angular/fire/compat/firestore';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RallyService } from '@shared/services/rally.service';
import { SharedModule } from '@shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';

import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      SharedModule,
      AngularFireModule.initializeApp(environment.firebase),
      ToastrModule.forRoot({
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        progressBar: true,
        closeButton: true,
        timeOut: 7000,
        enableHtml: true,
        messageClass: 'toast-message',
        tapToDismiss: false,
        extendedTimeOut: 5000
      }),
      FontAwesomeModule
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.emulators.authentication) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.emulators.firestore) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.emulators.functions) {
        connectFunctionsEmulator(functions, '127.0.0.1', 5001);
      }
      return functions;
    }),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.emulators.storage) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
    ScreenTrackingService,
    UserTrackingService,
    Location,
    DialogService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: ENABLE_PERSISTENCE,
      useValue: false
    },
    RallyService,
    provideAnimations()
  ]
}).catch((err) => console.error(err));
